import { createAction, props } from '@ngrx/store';

import {
    DatapointCandidate,
    DatapointDependency,
    Element,
    ElementBinding,
    ElementBindingStatus,
    TerminalUnitModelType,
} from '@priva/next-model';

export const getElementBindingCandidates = createAction(
    '[Element Bindings API] Get element binding candidates',
    props<{ elementId: string; dependencyId: string }>(),
);
export const getElementBindingCandidatesSuccess = createAction(
    '[Element Bindings API] Get element binding candidates (success)',
    props<{ bindingCandidates: Element[] }>(),
);
export const getElementBindingCandidatesByModelTypes = createAction(
    '[Elements API] Get element binding candidates by model types',
    props<{ modelTypes: TerminalUnitModelType[] }>(),
);
export const getElementBindingCandidatesByModelTypesSuccess = createAction(
    '[Elements API] Get element binding candidates by model types (success)',
    props<{ bindingCandidates: Element[] }>(),
);

export const getDatapointCandidates = createAction(
    '[Element Bindings API] Get datapoint candidates',
    props<{ datapointDependencyId: string }>(),
);
export const getDatapointCandidatesSuccess = createAction(
    '[Element Bindings API] Get datapoint candidates (success)',
    props<{ datapointCandidates: DatapointCandidate[] }>(),
);
export const getElementBindingCandidatesAutoSolve = createAction(
    '[Element Bindings API] Get element binding candidates for auto solve',
    props<{ elementId: string; dependencyId: string; zoneId: string }>(),
);
export const getElementBindingCandidatesAutoSolveSuccess = createAction(
    '[Element Bindings API] Get element binding candidates for auto solve (success)',
    props<{ elementId: string; dependencyId: string; zoneId: string; bindingCandidates: Element[] }>(),
);

export const getElementBindingStatus = createAction(
    '[Element Binding API] Get element binding status',
    props<{ elementId: string }>(),
);
export const getElementBindingStatusSuccess = createAction(
    '[Element Binding API] Get element binding status (success)',
    props<{ elementBindingStatus: ElementBindingStatus }>(),
);
export const getElementBindingStatusFailed = createAction(
    '[Element Binding API] Get element binding status (failed)',
    props<{ error?: string }>(),
);

export const createElementBinding = createAction(
    '[Element Bindings API] Create binding for element',
    props<{
        sourceElementId: string;
        dependencyId: string;
        targetElementId: string;
        targetOptionId?: string;
    }>(),
);
export const createElementBindingSuccess = createAction(
    '[Element Bindings API] Create binding for element (success)',
    props<{ element: Element }>(),
);
export const changeElementBinding = createAction(
    '[Element Bindings API] Change device binding',
    props<{ elementId: string; targetElementId: string }>(),
);
export const changeElementBindingSuccess = createAction(
    '[Element Bindings API] Change element binding (success)',
);

export const deleteElementBinding = createAction(
    '[Element Bindings  API] Delete element binding',
    props<{ elementId: string }>(),
);
export const deleteElementBindingSuccess = createAction(
    '[Element Bindings API] Delete element binding (success)',
);

export const createDatapointBinding = createAction(
    '[Element Bindings API] Create binding for datapoint',
    props<{
        sourceElementId: string;
        dependencyId: string;
        targetElementId: string;
        targetOptionId?: string;
    }>(),
);
export const createDatapointBindingSuccess = createAction(
    '[Element Bindings API] Create binding for datapoint (success)',
    props<{ dependency: DatapointDependency }>(),
);
export const changeDatapointBinding = createAction(
    '[Element Bindings API] Change datapoint binding',
    props<{ datapointDependencyId: string; targetDatapointDependencyId: string }>(),
);
export const changeDatapointBindingSuccess = createAction(
    '[Element Bindings API] Change datapoint binding (success)',
);

export const deleteDatapointBinding = createAction(
    '[Element Bindings  API] Delete datapoint binding',
    props<{ binding: ElementBinding }>(),
);
export const deleteDatapointBindingSuccess = createAction(
    '[Element Bindings API] Delete datapoint binding (success)',
);
