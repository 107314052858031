import { DatapointCandidate, Element, ElementBindingStatus } from '@priva/next-model';

import { BuildingState } from 'app/solution/building/state';
import { DaliMappingState } from 'app/solution/element/state/dali-mapping';
import { IOMappingState } from 'app/solution/element/state/io-mapping';
import { ModbusMappingState } from 'app/solution/element/state/modbus-mapping';

export interface ElementsStateContainer {
    elements: ElementsState;
    ioMapping: IOMappingState;
    modbusMapping: ModbusMappingState;
    daliMapping: DaliMappingState;
    building: BuildingState;
}

export interface ElementsState {
    elements: Element[];
    elementBindingStatuses: ElementBindingStatus[];
    bindingCandidates: Element[];
    datapointCandidates: DatapointCandidate[];
    error?: string;
}

export const initialElementsState: ElementsState = {
    error: undefined,
    elements: [],
    elementBindingStatuses: [],
    bindingCandidates: [],
    datapointCandidates: [],
};
